.AuthLay{
    background-image: url(../../../assets/images/AuthLay.png);
    background-size: cover;
    /* min-height: 570px; */
    background-repeat: no-repeat;
    /* margin-top: 100px; */
    justify-content: center;
    border-radius: 45px;
    /* margin: 6% 15% 5% 15%; */
    margin-bottom: 0px;
    background: #fff;
    padding: 30px;
    position: relative;
  
    
}
.AuthLayLog{
    /* background-image: url(../../../assets/images/AuthLay.png);
    background-size: contain; */
    /* min-height: 700px; */
    background-repeat: no-repeat;
    /* margin-top: 70px; */
    /* justify-content: center; */
    border-radius: 45px;
    width: 100% !important;
    background: #fff;
   
   

}
.vector1{
    position: absolute;
    top: 5%;
    max-width: 20px;
    left: 5%;
}
.vector2{
    position: absolute;
    top: 10%;
    max-width: 30px;
    right: 6%;
}
.vector3{
    position: absolute;
    max-width: 50px;
    bottom: 10%;
    left: 2%;
}
.logo_parent{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
}


.Logo{
    /* padding-top: 25px;
    padding-left: 28%; */
    max-width: 180px;
   
   
}
.Phone{
    font-size: 16px;
    font-weight: 700;
    color: #1F6CAB;
}
.call{
    /* padding-left: 32%; */
    bottom: 0px !important;
    text-align: center;
    margin-top: 30px;
    

}
.Restcallbtn{
    margin-top: 50px;
}
.call svg{
    width: 18px;
}
.Privacy{
    color: #505050;
    text-align: center;
    font-weight: 600;
font-size: 12px;
font-family: 'outfitReg';
/* margin-top: 70px;
padding-bottom:20px; */

}
@media screen and (max-width: 3000px) {
    .AuthLayLog{
        /* background-image: url(../../../assets/images/AuthLay.png); */
        background-size: cover;
        /* min-height: 500px; */
        background-repeat: no-repeat;
        /* margin-top: 20px; */
        /* justify-content: center; */
        border-radius: 45px;
        position: relative;
       
       
    
    }
    .Privacy{
        color: #505050;
        text-align: center;
        font-weight: 600;
    font-size: 12px;
    font-family: 'outfitReg';
    /* margin-top: 25px; */
    /* padding-bottom:20px; */
    margin: 30px 0px;
    
    }
    .Privacy_pagetext{
        margin-bottom: 0px;
    }
    .Logo{
        /* padding-top: 25px;
        padding-left: 35%;
       
        height: 84px; */
       
       
    }
}
@media screen and (min-width:3000) and (max-width: 6000px) {
    .AuthLayLog{
        background-image: url(../../../assets/images/AuthLay.png);
        background-size: cover;
        /* min-height: 500px; */
        background-repeat: no-repeat;
        /* margin-top: 90px; */
        /* justify-content: center; */
        border-radius: 45px;
       
       
    
    }
}

@media (min-width:300px) and (max-width:768px){
    .imgContainer {
        display: none;
      }
   
      .Auth_Reset__Xm29G {
        margin-top: 5px;
      }
      .Restcallbtn{
        margin-top: 30px;
     }
     
     
}