body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E4FBFB !important;
  height: 100%;
  margin: 0;
  
}
@font-face {
  font-family: 'outfitReg';
  src: url(./assets//fonts/Outfit-Regular.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logBg{
  background-image: url(./assets/images/Background.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh !important;
}
.filter_boxes{
  margin-right: 80px;
}
.mainHead{
  color: #1F6CAB;
  font-weight: 700;
/* font-size: 24px; */
font-family: 'outfitReg';
/* line-height: 30px;
padding-left: 30%;
padding-top: 30px; */
}
.box_parent{
  padding: 0px 70px!important;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}
.imgContainer{
  background-image: url(./assets/images/verify.png);
  background-size: cover;

  background-repeat: no-repeat;
  min-height: 100vh !important;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
 

}

.verification-btn button{
  margin-bottom: 20px;
}
.tooltipss {
  position: relative !important;
  cursor: pointer;
}

.tooltipss .tooltiptext {
  visibility: hidden;
  width: 430px;
  background-color: #b8face;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: 2%;
  line-height: 30px;
  border: 1px solid #1e1e1e;
}

.tooltipss:hover .tooltiptext {
  visibility: visible;
}
.parent_base {
  /* display: flex;
align-items: center; */
  /* justify-content: space-between; */
}
.name_b {
  text-align: left;
}
.left_base {
  display: flex;
  align-items: center;
  gap: 40px;
}
.right_base {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verifySide{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}
.tabCon{
 
  width: 100%;
  background-color: #FFFFFF;
  min-height: 65vh;
  
}

.tabHead{
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
padding: 10px;

}


.successCard{
  background: #9EFFB4;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
width: 100%;
height: 120px;
padding: 20px;

}
.succLog{
 
    background: #1F6CAB;
border-radius: 5px;
width: 110px;
height: 36px;
outline: none;
color: #FFFFFF;
font-size: 16px;
border: none;
margin-top: 25px;
justify-content: center;
font-family: 'outfitReg';
margin-bottom: 20px;


}
.loader{
  padding-top: 170px;
}
.successCard h3{
  color: #1F6CAB;
  padding-top: 15px;
  font-weight: 700;
font-size: 17px;
line-height: 24px;
font-weight: 400 !important;
}
.SuccessPad{
  padding: 50px 30px;
}
.table tr{
  border-bottom: 1px solid #000000;
}
 .tabHead th{
  color: #505050;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.tabSubHead td{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #505050;
  font-family: 'outfitReg';
}

.Ready{
  background: #89FFAA;
  border-radius: 20px;
  width: 100px;
height: 42px !important;
padding: 12px 28px;
/* cursor: pointer; */
font-size: 14px;
text-align: center;
margin-top: 9%!important;
display: flex;
align-items: center;
justify-content: center;



}
.Partial{
  
  background:#E7F880;
  border-radius: 20px;
  width: 100px;
display: flex;
padding: 12px 25px;
cursor: pointer;
text-align: center;
margin-top: 9%!important;
}
.Drop{
  background:#F2A38A;
  border-radius: 20px;
  width: 107px;
display: flex;
padding: 12px 12px;
font-size: 14px !important;
/* cursor: pointer; */
text-align: center;
margin-top: 9% !important;

}
.Action{
  display: flex;
  
 
}
.custom-modal .ant-modal-content {
  background-color: #B8FACE;
  border: 1px solid #505050;
  width: 500px;
  
}
.PatName{
  font-weight: 700;
font-size: 18px;
line-height: 23px;
font-family: 'outfitReg';
color: #505050;;
}
.patDet{
  font-weight: 400;
font-size: 16px;
line-height: 20px;
font-family: 'outfitReg';
color: #505050;
padding-top: 10px;

}
.error{
  text-align: center;
  font-size: 17px;
  color: #E71212;
  font-family: 'outfitReg';
  padding-top: 10px;
}
.react-pdf__Page__textContent{
  display: none !important;

 
}
.react-pdf__Page__canvas{
  width: 800px !important;
  height: 1150px !important;
  
}
.react-pdf__Page__annotations{
  height: 0px !important;
  display: none !important;
}
.pdfModal{
  padding: 0px !important;
  background-color: #E4FBFB;
}
.ant-modal {
  width: 870px !important;
}
.pdfBtns{
  /* float: right; */
  display: flex;
  padding-bottom: 15px;
}
.pdf{
  display: flex;
  justify-content: flex-end!important;
  padding-right: 30px;
}
.downLoad{
  padding: 10px;
  border-radius: 5px;
  background: #1F6CAB;
  color: #FFFFFF;
  cursor: pointer;
}
.close{
  padding: 10px;
  border-radius: 5px;
  background: #FF9B21;;
  color: #FFFFFF;
  margin-left: 20px;
  cursor: pointer;

}
.pdfHead{
  font-weight: 700;
font-size: 18px;
line-height: 23px;
color: #505050;
padding-top: 20px;
padding-bottom: 20px;
padding-left: 25px;
}
.pdf-container{
  width: 100%;
  height: 800px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorNew{
  text-align: left;
  font-size: 14px;
  color: #E71212;
  font-family: 'outfitReg';
  padding-top: 5px;
  padding-bottom: 5px;

}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-modal .ant-modal-content {
  padding: 0px !important;
}
.dropdown{
    position: relative;
}
.comment-box{
    position: absolute;
    right: 46%;
    top: 49px;
    z-index: 9;
    display: none;
    background-color: #E4FBFB;
    padding: 20px;
    border-radius: 5px;
}
.comment_box{
  background: #fff;
  padding: 10px;
  border: none;
  outline: none;
}
.dropdown .abc:hover + .comment-box{
    display: block;

}
.msgBox{
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}
.msgSubmit{
  padding: 7px 5px;
  background-color: #1F6CAB;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 16px;
  width: 70px;
  float: right;
  text-align: center;
 margin-top: 5px;
 cursor: pointer;
}
.msgHead{
  color: #505050;
  font-weight: 700;
font-size: 18px;
line-height: 23px;
font-family: 'outfitReg';
padding-bottom: 5px;
}
.hide {
    display: block;
}
    
.myDIV {
  cursor: pointer;

}
.filterCont{
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #828282;
  padding: 25px;
}
.filtDet p{
  font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #505050;


}
:where(.css-dev-only-do-not-override-15rg2km).ant-picker {
  padding: 4px 28px 4px;
  width: 100%;
}
.FilSearch{
  background: #1F6CAB;
border-radius: 5px;
width: 90px;
height: 42px;
outline: none;
cursor: pointer;
color: #FFFFFF;
border: none;
font-size: 16px;
padding: 7px 7px;
margin-right: 20px;

font-family: 'outfitReg';

}
.FilClear{
  background: #F46C09  ;
  border-radius: 5px;
  width: 80px;
  height: 42px;
  outline: none;
  cursor: pointer;
  color: #FFFFFF;
  border: none;
  font-size: 16px;
  padding: 7px 5px;
  margin-right: 20px;
  
  font-family: 'outfitReg';

}
.Box{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;

}
.note{
  font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #9E9A9A;
 
font-family: 'outfitReg';
}
.tabHead td,th{
  padding: 12px;
}
.tabSubHead td{
  padding: 12px;

}
@media screen and (max-width: 3000px) {
  .th{
    color: #505050;
    font-weight: 800;
    font-size: 13px;
    line-height: 15px;
  }
  td,th{
    padding: 10px;
  }
  .tabSubHead td{
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #505050;
    font-family: 'outfitReg';
  }
  .mainHead{
    color: #1F6CAB;
    font-weight: 700;
  /* font-size: 20px; */
  font-family: 'outfitReg';
  /* line-height: 30px;
  padding-left: 33%;
  padding-top: 10px; */
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
  }
  .error{
    text-align: center;
    font-size: 15px;
    color: #E71212;
    font-family: 'outfitReg';
    padding-top: 0px;
  }
}
@media (min-width:768px) and (max-width:992px){
  .filter_boxes {
    margin-right: 0px!important;
  }
  .Header_Footer__1HMcx p {
    text-align: center!important;
  }

}
@media (min-width:300px) and (max-width:767px){
  .mainHead{
    /* padding-left: 23%;
    font-size: 18px;
    line-height: 27px; */
  }
  .Auth_Card__5vTeM {
    padding: 10px 30px 20px 30px!important;
  }
  .AuthLayout_call__5gVO3 svg{
    max-width: 24px;
  }
  .AuthLayout_Phone__Rriwl {
    font-size: 16px!important;
  }
  .Auth_Auth__TWMsw h3 {
    font-size: 20px!important;
  }
  .AuthLayout_Privacy__KR6\+v {
    margin-top: 15px!important;
    padding: 0px 40px;
  }
  .Auth_Auth__TWMsw label {
    font-size: 14px!important;
  }
  .Auth_Auth__TWMsw input {
    height: 35px!important;
    padding-left: 10px;
    font-size: 14px;
  }
  .imgContainer {
    display: none;
    margin: 5% 5% 5% 5%!important;
  }
  .Header_Header__uqnB3 {
    height: 107px!important;
    padding: 30px 25px!important;
  }
  .Header_User__o6uIW {
    display: flex;
    padding-top: 5px;
    align-items: center;
  }
  .Header_Profle__BzNVw {
    width: 40px!important;
    height: 40px!important;
  }
  .Header_Profle__BzNVw img{
    max-width: 40px;
  }
  .Header_Logout__fsR5w {
    margin-top: 0px!important;
  }
  .Header_MidHead__gufz1 {
    display: flex;
    flex-direction: column!important;
    gap: 15px!important;
    /* justify-content: space-between; */
    padding: 30px 25px;
  }
  .Header_searchbar__4qYq7 {
    width: 130px!important;
  }
  .Header_searchIcon__17k68 {
    display: none;
  }
  .Header_call__lrgtk {
    margin-bottom: 10px;
  }
  .Header_Footer__WrD4p p {
    text-align: center;
  }
  .Header_call__lrgtk svg{
    max-width: 24px;
  }
  .Header_call__lrgtk {
    font-size: 18px!important;
  }
  .bill_no {
    margin-bottom: 20px;
  }
  .hospital_id {
    padding-left: 30px;
    margin-bottom: 20px;
  }
  .box_parent {
    padding: 0px 20px!important;
  }
  .AuthLayout_call__5gVO3 {
    margin-top: 10px;
  }
  .AuthLayout_vector3__YyYtm {
    position: absolute;
    max-width: 45px;
    bottom: 11%;
    left: 3%;
  }
  .SuccessPad {
    padding: 20px 0px;
  }
  .successCard h3 {
    font-size: 16px;
    padding-top: 5px;
  }
  .filter_boxes{
    margin-right: 0px;
  }
  .Header_MidHead__E1m3E {
    display: flex;
    justify-content: space-between;
    padding: 30px 25px;
   gap: 20px;
    flex-direction: column!important;
}
.Header_searchbar__hVtik {
  width: 130px!important;
}

.Header_searchIcon__CNM9a {
  display: none!important;
}
.Header_Header__0SiXG {
  padding: 15px 10px!important;
}
.Header_Header__0SiXG {
  height: 80px!important;
}

 
 


 





}