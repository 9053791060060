.Header{
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    height: 90px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 18px 68px;
}
.MidHead{
    display: flex;
    justify-content: space-between;
    padding: 30px 25px;

}
.Filter{
    background: #1F6CAB;
border-radius: 5px;
width: 130px;
height: 46px;
outline: none;
cursor: pointer;
color: #FFFFFF;
border: none;
font-size: 16px;
padding: 9px 12px;
margin-right: 20px;

font-family: 'outfitReg';

}
.PatntName{
    color: #1F6CAB;
    font-weight: 500;
font-size: 25px;
line-height: 32px;
font-family: 'outfitReg';
font-size: 25px;
}
.UserName{
    font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #808080;
font-family: 'outfitReg';
font-size: 16px;
margin-right: 20px;
padding-top: 10px;
margin-bottom: 0px;
padding-top: 0px;

}
.Profle img{
    width: 180px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
}
.User{
    display: flex;
    padding-top: 5px;
    align-items: center;

}
.Logout{
    margin-top: 10px;
    cursor: pointer;
}
.searchbar {
 
    width: 435px;
    height: 46px;
    /* left: 25px; */
   
    border: 1px solid #1F6CAB;
    border-radius: 5px;
    position: relative;
    background: #F4F4F4;
   
    padding-bottom: 3px;
    position: relative;
   
}
.searchList{
    position: relative;
  display: inline-block;
}
.searchListCont{
    display: none;
  position: absolute;
  background-color: #FFFFFF;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  max-height: 700px;
  overflow-y: scroll;
}
/* .searchList:hover .searchListCont{
    display: block;
} */

.MidHead::-webkit-input-placeholder {
    color:#505050 ;
    font-weight: 400;
    font-size: 14px;
    padding: 15px;
    display: flex;
    align-items: center;
    font-family: 'outfitReg';
    background-image:url(../../assets/images/search.png);
    background-repeat: no-repeat;
    background-position: right ;
    background-position-x: 405px;
}
.SearchItem{
    color: #505050;
    font-weight: 400;
font-size: 16px;
line-height: 20px;
font-family: 'outfitReg';
border-bottom: 1px solid #C4C4C4;
padding-bottom: 20px;
cursor: pointer;


}
.noResult{
    color: #505050;
    font-weight: 400;
font-size: 16px;
line-height: 20px;
font-family: 'outfitReg';

padding-bottom: 20px;
cursor: pointer;


}
.searchIcon{
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust this value to position the icon as desired */
    transform: translateY(-50%);
    pointer-events: none;
   
}
.Footer{
   width: 100%;
    /* position: fixed; */
    bottom: 0;
    padding: 30px 40px 10PX 40PX;
}
.call{
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
text-align: center;

color: #1F6CAB;
}
.Footer p{
    color: #6D6C6C;
    font-weight: 400;
font-size: 16px;
line-height: 20px;
font-family: 'outfitReg';
}
.Footer h5{
    color: #00D3EB;
    font-weight: 400;
font-size: 16px;
line-height: 20px;
font-family: 'outfitReg';
text-align: center;

}
@media screen and (max-width: 1700px) {
.Filter{
    background: #1F6CAB;
border-radius: 5px;
width: 130px;
height: 46px;
outline: none;
cursor: pointer;
color: #FFFFFF;
border: none;
font-size: 15px;
padding: 12px 15px;
margin-right: 20px;

font-family: 'outfitReg';

}
}


